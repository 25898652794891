import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import APIClientUpload from '../../clients/abdaUpload'
import { merge } from 'lodash'

const resourceName = 'student'

const GetAll = (criteria, pagination) => SocialProjectManager.get(resourceName, { 
  params: merge(criteria, pagination) })

const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)
const GetByName = name => SocialProjectManager.get(`${resourceName}/get-by-name/${name}`)
const GetNotInClass = (search, classId) => SocialProjectManager.get(`${resourceName}/${search}/class/${classId}`)
const GetByDocument = value => SocialProjectManager.get(`${resourceName}/document/${value}`)
const GetSearch = (name) => SocialProjectManager.get(resourceName, { 
  params: merge(
    { name: name }) 
  })

const Create = data => SocialProjectManager.post(resourceName, data)
const UploadPhoto = (id, config = {}) => APIClientUpload.post(`${resourceName}/${id}/profile-photo`, config)

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)
const UpdateReenrollment = id => SocialProjectManager.put(`${resourceName}/${id}/year-reenrollment`)
const Reactivate = id => SocialProjectManager.put(`${resourceName}/${id}/reactivate`)
const InactiveStudents = data => SocialProjectManager.put(`${resourceName}/batch/inactive`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const GetTotal = () => SocialProjectManager.get(`${resourceName}/total`)
const GetTotalWithMeasures = () => SocialProjectManager.get(`${resourceName}/measures/total`)
const S3FileExists = id => APIClientUpload.get(`${resourceName}/${id}/photo-exists`)

const FaceSheet = id => SocialProjectManager.get(`${resourceName}/face-sheet/${id}`)

const ProofEnrollmentGeneratePDF = id => SocialProjectManager.get(`${resourceName}/proof-enrollment-pdf/${id}`)
const ProofEnrollmentSendEmail = (data, id) => SocialProjectManager.post(`${resourceName}/proof-enrollment-email/${id}`, data);
const GetAccountUpdated = (id) => SocialProjectManager.get(`${resourceName}/account-id/${id}`)

const GetHistoricSms = id => SocialProjectManager.get(`${resourceName}/historic/${id}`)
const SaveHistoricSms = data => SocialProjectManager.post(`${resourceName}/save-historic-sms/`, data)

const ReasonInactiveStudent = data => SocialProjectManager.put(`${resourceName}/inactive-reason`, data)
const GetReasonInactive = id => SocialProjectManager.get(`${resourceName}/get-reason-inactive/${id}`)
const InactiveReasonStudentInactive = studentInactiveId => SocialProjectManager.delete(`${resourceName}/inactive-reason-inactive/${studentInactiveId}`)

const GenerateExcelByCriteria = criteria => SocialProjectManager.post(`${resourceName}/generate-student-excel/`, criteria)

const StudentRepository = {
  GetById,
  GetAll,
  GetNotInClass,
  GetByDocument,
  GetSearch,
  GetTotal,
  GetTotalWithMeasures,
  Create,
  Update,
  UpdateReenrollment,
  Reactivate,
  Delete,
  InactiveStudents,
  UploadPhoto,
  S3FileExists,
  FaceSheet,
  GetAccountUpdated,
  ProofEnrollmentGeneratePDF,
  ProofEnrollmentSendEmail,
  SaveHistoricSms,
  GetHistoricSms,
  ReasonInactiveStudent,
  GetReasonInactive,
  InactiveReasonStudentInactive,
  GetByName,
  GenerateExcelByCriteria
}

export default StudentRepository