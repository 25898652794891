const ReportRoutes = [
  {
    path: '/relatorios/alunos',
    meta: { title: 'Relatório de Alunos' },
    name: 'ReportStudentList',
    component: () => import('@/views/report/student-list/StudentList.vue')
  },
  {
    path: '/relatorios/alunos-por-modalidade',
    meta: { title: 'Alunos por Modalidade' },
    component: () => import('@/views/report/dashboard/student-modality/StudentModality.vue')
  },
  {
    path: '/relatorios/alunos-por-local-de-treinamento',
    meta: { title: 'Alunos por Local de Treinamento' },
    component: () => import('@/views/report/dashboard/student-localtraining/StudentLocalTraining.vue')
  },
  {
    path: '/relatorios/alunos-por-idade',
    meta: { title: 'Alunos por Idade' },
    component: () => import('@/views/report/dashboard/student-age/StudentAge.vue')
  },
  {
    path: '/relatorios/alunos-por-genero',
    meta: { title: 'Alunos por Gênero' },
    component: () => import('@/views/report/dashboard/student-gender/StudentGender.vue')
  },
  {
    path: '/relatorios/alunos-por-escola',
    meta: { title: 'Alunos por Escola' },
    component: () => import('@/views/report/dashboard/student-school/StudentSchool.vue')
  },
  {
    path: '/relatorios/alunos-por-renda-familiar',
    meta: { title: 'Alunos por Renda Familiar' },
    component: () => import('@/views/report/dashboard/student-familyincome/StudentFamilyIncome.vue')
  },
  {
    path: '/relatorios/alunos-por-bairro',
    meta: { title: 'Alunos por Bairro' },
    component: () => import('@/views/report/dashboard/student-district/StudentDistrict.vue')
  },
  {
    path: '/relatorios/alunos-por-ocorrencia',
    meta: { title: 'Alunos por Ocorrência' },
    component: () => import('@/views/report/dashboard/student-occurrence/StudentOccurrence.vue')
  },
  {
    path: '/relatorios/frequencia',
    meta: { title: 'Frequência' },
    component: () => import('@/views/report/dashboard/classlessonstudent/ClassLessonStudent.vue')
  },
  {
    path: '/relatorios/medicoes',
    meta: { title: 'Medições' },
    component: () => import('@/views/report/dashboard/student-measurement/StudentMeasurement.vue')
  },
  {
    path: '/relatorios/alunos-por-projeto-social',
    meta: { title: 'Alunos por Projeto Social' },
    component: () => import('@/views/report/dashboard/student-socialproject/StudentSocialProject.vue')
  },
  {
    path: '/relatorios/fisiologia',
    meta: { title: 'Fisiologia' },
    component: () => import('@/views/report/dashboard/student-physiology/StudentPhysiology.vue')
  },
  {
    path: '/relatorios/academia',
    meta: { title: 'Academia' },
    component: () => import('@/views/report/dashboard/student-gym/StudentGym.vue')
  },
  {
    path: '/relatorios/alunos-ausente',
    meta: { title: 'Alunos Ausentes' },
    component: () => import('@/views/report/dashboard/student-absent/StudentAbsent.vue')
  },
  {
    path: '/relatorios/atc',
    meta: { title: 'ATC' },
    component: () => import('@/views/report/dashboard/atc/atc.vue')
  }
]

export default ReportRoutes
