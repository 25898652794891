export const getMenuData = [
  {
    title: 'Administração',
    key: 'adm',
    icon: 'fa fa-coffee',
    children: [
      {
        title: 'Contas',
        key: 'account',
        icon: 'fa fa-address-book-o',
        url: '/contas',
      },
      {
        title: 'Permissões',
        key: 'usersconfig',
        icon: 'fa fa-low-vision',
        url: '/configurar-usuarios',
      },
      {
        title: 'Grupos',
        key: 'groups',
        icon: 'fa fa-users',
        url: '/grupos',
      },
      {
        title: 'Logs de SMS',
        key: 'smsLogs',
        icon: 'fa fa-globe',
        url: '/sms-logs',
      },
      {
        title: 'QRCodes',
        key: 'qrCodes',
        icon: 'fa fa-qrcode',
        url: '/qrcode-list',
      }
    ]
  },
  {
    title: 'Dashboard',
    key: 'dashboard',
    icon: 'fa fa-pie-chart',
    url: '/dashboard',
  },
  {
    title: 'Alunos',
    key: 'student',
    icon: 'fa fa-user-plus',
    url: '/alunos',
  },
  {
    title: 'Cadastros',
    key: 'register',
    icon: 'fa fa-check-square-o',
    children: [
      {
        title: 'Modalidades',
        key: 'modality',
        icon: 'fa fa-reorder',
        url: '/cadastros/modalidades',
      },
      {
        title: 'Locais de treinamento',
        key: 'localtraining',
        icon: 'fa fa-globe',
        url: '/cadastros/locais-de-treinamento',
      },
      {
        title: 'Categorias',
        key: 'category',
        icon: 'fa fa-calendar-check-o',
        url: '/cadastros/categorias',
      },
      {
        title: 'Professores',
        key: 'teacher',
        icon: 'fa fa-graduation-cap',
        url: '/cadastros/professores',
      },
      {
        title: 'Tipos de benefício',
        key: 'benefittype',
        icon: 'fa fa-star',
        url: '/cadastros/tipos-de-beneficio',
      },
      {
        title: 'Escolas',
        key: 'school',
        icon: 'fa fa-university',
        url: '/cadastros/escolas',
      },
      {
        title: 'Tipos de anexo',
        key: 'attachmenttype',
        icon: 'fa fa-paperclip',
        url: '/cadastros/tipos-de-anexo',
      },
      {
        title: 'Tipos de ocorrência',
        key: 'occurrencetype',
        icon: 'fa fa-wpforms',
        url: '/cadastros/tipos-de-ocorrencia',
      },
      {
        title: 'Tipos de atendimento social',
        key: 'socialattendancetype',
        icon: 'fa fa-weixin',
        url: '/cadastros/tipos-de-atendimento-social',
      },
      {
        title: 'Entidades sociais',
        key: 'socialentity',
        icon: 'fa fa-institution',
        url: '/cadastros/entidades-sociais',
      },
      {
        title: 'Anamnese',
        key: 'anamnese',
        icon: 'fa fa-institution',
        url: '/cadastros/anamese',
      },
      {
        title: 'Mensagem SMS',
        key: 'messagesms',
        icon: 'fa fa-volume-control-phone',
        url: '/cadastros/mensagem-sms',
      }
    ]
  },
  {
    title: 'Turmas',
    key: 'class-list',
    icon: 'fa fa-users',
    url: '/turmas',
  },
  {
    title: 'Lista de Espera',
    key: 'waiting-list',
    icon: 'fa fa-hourglass-2',
    children: [
      {
        title: 'Cadastros',
        key: 'waiting-list',
        icon: 'fa fa-list-ul',
        url: '/lista-de-espera',
      },
      {
        title: 'Testes/Matrícular',
        key: 'waiting-result-test',
        icon: 'fa fa-address-card-o',
        url: '/resultado-testes',
      },
      {
        title: 'Lista web',
        key: 'waitingList-web',
        icon: 'fa fa-hourglass',
        url: '/lista-de-espera-web'
      },
      {
        title: 'Gráfico/Interesses',
        key: 'waitingList-graphic',
        icon: 'fa fa-bar-chart',
        url: '/grafico-lista-de-espera'
      },
    ]
  },
  {
    title: 'Relatórios',
    key: 'report',
    icon: 'lnr lnr-chart-bars',
    children: [
      {
        title: 'Alunos',
        key: 'student-list',
        icon: 'fa fa-user-plus',
        url: '/relatorios/alunos',
      },
      {
        title: 'Frequência',
        key: 'classlessonstudent',
        icon: 'fa fa-line-chart',
        url: '/relatorios/frequencia',
      },
      {
        title: 'Alunos por modalidade',
        key: 'student-modality',
        icon: 'fa fa-reorder',
        url: '/relatorios/alunos-por-modalidade',
      },
      {
        title: 'Alunos por local de treinamento',
        key: 'student-localtraining',
        icon: 'fa fa-globe',
        url: '/relatorios/alunos-por-local-de-treinamento',
      },
      {
        title: 'Alunos por escola',
        key: 'student-school',
        icon: 'fa fa-institution',
        url: '/relatorios/alunos-por-escola',
      },
      {
        title: 'Alunos por renda familiar',
        key: 'student-familyincome',
        icon: 'fa fa-dollar',
        url: '/relatorios/alunos-por-renda-familiar',
      },
      {
        title: 'Alunos por gênero',
        key: 'student-gender',
        icon: 'fa fa-venus-mars',
        url: '/relatorios/alunos-por-genero',
      },
      {
        title: 'Alunos por idade',
        key: 'student-age',
        icon: 'fa fa-birthday-cake',
        url: '/relatorios/alunos-por-idade',
      },
      {
        title: 'Alunos por bairro',
        key: 'student-district',
        icon: 'fa fa-map-pin',
        url: '/relatorios/alunos-por-bairro',
      },
      {
        title: 'Alunos por ocorrência',
        key: 'student-occurrence',
        icon: 'fa fa-bullhorn',
        url: '/relatorios/alunos-por-ocorrencia',
      },
      {
        title: 'Medições / IMC',
        key: 'student-measurement',
        icon: 'fa fa-arrows-v',
        url: '/relatorios/medicoes',
      },
      {
        title: 'Alunos por Projeto Social',
        key: 'student-socialproject',
        icon: 'fa fa-smile-o',
        url: '/relatorios/alunos-por-projeto-social',
      },
      {
        title: 'Fisiologia',
        key: 'student-physiology',
        icon: 'fa fa-heartbeat',
        url: '/relatorios/fisiologia',
      },
      {
        title: 'Academia',
        key: 'student-gym',
        icon: 'fa fa-male',
        url: '/relatorios/academia',
      },
      {
        title: 'Alunos Ausentes',
        key: 'student-absent',
        icon: 'fa fa-user-times',
        url: '/relatorios/alunos-ausente',
      },
      {
        title: 'Comunidade Digital',
        key: 'atc',
        icon: 'fa fa-address-card',
        url: '/relatorios/atc',
      }
    ],
  },
  {
    title: 'Ferramentas',
    key: 'tools',
    icon: 'fa fa-wrench',
    children: [
      {
        title: 'Situação em lote',
        key: 'batch-status',
        icon: 'fa fa-thumbs-up',
        url: '/ferramentas/situacao-em-lote'
      },
      {
        title: 'Exportar em excel',
        key: 'export-excel',
        icon: 'fa fa-file-text',
        url: '/ferramentas/exportar-excel'
      },
      {
        title: 'SMS em lote',
        key: 'batch-sms',
        icon: 'fa fa-volume-control-phone',
        url: '/ferramentas/sms-em-lote'
      },
      {
        title: 'Reuniões',
        key: 'meeting-student',
        icon: 'fa fa-gavel',
        url: '/ferramentas/reuniao-aluno'
      },
      {
        title: 'QRCode ABDA',
        key: 'single-qrcode',
        icon: 'fa fa-qrcode',
        url: '/ferramentas/qrcode-funcionario-e-visitante'
      },
      // {
      //   title: 'Formulário Web',
      //   key: 'web-form',
      //   icon: 'fa fa-wpforms',
      //   url: '/ferramentas/formulario-web'
      // }
    ]
  },
  {
    title: 'Professores',
    key: 'teachers',
    icon: 'fa fa-graduation-cap',
    children: [
      {
        title: 'Fisiologia',
        key: 'jump',
        icon: 'fa fa-heartbeat',
        url: '/fisiologia',
      },
      {
        title: 'Academia',
        key: 'gym',
        icon: 'fa fa-male',
        url: '/academia',
      },
      {
        title: 'Fluxogramas',
        key: 'flowchart',
        icon: 'fa fa-area-chart',
        url: '/professores/fluxograma',
      }
    ]
  },
  {
    title: 'Restaurante',
    key: 'restaurant',
    icon: 'fa fa-free-code-camp',
    url: '/restaurante/refeicoes',
  },
]
